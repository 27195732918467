import React, { Component } from "react";
import { toast } from "react-toastify";
import queryString from "query-string";
import MediaQuery from "react-responsive";
import styled from "styled-components";
import { setRef, getRef } from "../helpers/utils";

// Layout
import Layout from "../components/layout";

// Components
import Hero from "../components/hero";
import Quotes from "../components/quotes";
import Logos from "../components/logos";
import Example from "../components/example";
import Section from "../components/section";
import Testimonials from "../components/testimonials";
import Cta from "../components/cta";
import ModalExample from "../components/modal-example";
import SectionSlider from "../components/section-slider";

import Seo from "../components/seo";

// Logos
import oreaLogo from "../images/logos/orea-logo.png";
import c21Logo from "../images/logos/logo-c21.png";
import expLogo from "../images/logos/logo-exp.png";
import remaxLogo from "../images/logos/logo-remax.png";
import kwLogo from "../images/logos/kw-logo.png";

// Images
import wsst2020 from "../images/fb-testimonial.png";
import facebookGroup from "../images/facebook-group.png";
import trackVideo from "file-loader!../images/track/real-estate-cc.vtt";
import sectionTrackVideo from "file-loader!../images/track/section-cc.vtt";
import trackVideoSlider from "file-loader!../images/track/t2.vtt";

import sectionTop from "../images/section-top.png";
import sectionBottom from "../images/section-bottom.png";

const SectionSliderContain = styled.section`
  padding: 5rem 1rem 0;
`;

export default class RealEstate extends Component {
  state = {
    registerUrl:
      "https://quickpageapp.com/register?p=price_1H7jaaIFjTzQdf8AQ16cnSV0",
    visible: false,
  };

  componentDidMount() {
    const qs = queryString.parse(window.location.search).s;

    if (qs) {
      toast.success(
        "Thank you for your request! Please check your email and or text!😎"
      );
      window.history.replaceState(null, null, "/");
    }
    setRef(this.props.location);

    const ref =
      getRef(this.props.location) ||
      queryString.parse(this.props.location.search).ref;

    if (ref) {
      this.setState({
        registerUrl: `https://quickpageapp.com/register?p=price_1H7jaaIFjTzQdf8AQ16cnSV0&ref=${ref}`,
      });
    }
  }

  linkClickHandler = (evt) => {
    evt.preventDefault();
    this.setState({ visible: !this.state.visible });
  };

  renderFBLists() {
    return (
      <ul>
        <li>See exactly how other agents are winning with Quickpage</li>
        <li>Join weekly webinars to speed up your success</li>
        <li>Participate in drawings and giveaways</li>
        <li>
          Learn tips and techniques from sales leaders across multiple
          industries
        </li>
      </ul>
    );
  }

  renderSectionHow() {
    return (
      <>
        <h4>Some of the ways agents are using Quickpage</h4>
        <br />
        <ul>
          <li>Virtual Home Tours</li>
          <li>Personal video replies</li>
          <li>Sharing Testimonial Videos</li>
          <li>Neighborhood Walk Through Videos</li>
          <li>Sharing documents like feature sheets</li>
          <li>Screen recordings to explain numbers</li>
        </ul>
        <br />
      </>
    );
  }

  render() {
    const { registerUrl, visible } = this.state;
    const quotes = [
      {
        quote: "“Best investment I've made as a real estate agent”",
        name: "Jason Steele",
        company: "Royal LePage",
      },
      {
        quote: `“One of the greatest "tools" for the industry that I have ever experienced”`,
        name: "Rick Sergison",
        company: "EXP Reality, Managing Broker",
      },
      {
        quote:
          "“It took me under 10 minutes to set up and send my first video”",
        name: "Shannon Unger",
        company: "RE/MAX Legend",
      },
    ];

    const logos = [oreaLogo, c21Logo, expLogo, remaxLogo, kwLogo];
    return (
      <Layout cssClass="bg-re" location={this.props.location}>
        <Seo
          title="The quickest, most effective closing tool in Real Estate | Quickpage"
          description="Quickpage is a video follow-up tool for sales that's increasing engagement by 200%. Video email and texting is the future of sales."
          keywords="video, email, video email, email video, sales app"
          url="https://sutton.quickpage.io"
        />

        <Hero
          tagline="Fastest growing video follow-up solution in the world"
          heading={`The <span>quickest</span>, most effective closing tool in Real Estate`}
          excerpt={`<div><p><span>Quickpage</span> is the fastest growing video follow up solution in the world.<p> <p>Set yourself apart from the competition and deliver the most unique experience your customer has ever seen.</p></div>`}
          video="https://dkgs2aqh5w6kx.cloudfront.net/media/D586358F-0DE8-4D22-B055-7FA2229A145C/qkdGvByFmBcqWQLNj.mp4-web.mp4"
          videoControls={true}
          poster="https://dkgs2aqh5w6kx.cloudfront.net/media/D586358F-0DE8-4D22-B055-7FA2229A145C/qkdGvByFmBcqWQLNj.mp4-poster-00001.png"
          track={trackVideo}
          location={this.props.location}
          gaTrackLabel="Real estate hero"
          cssClass="bg-re"
          industry={"real-estate"}
        />

        <Quotes quotes={quotes} />

        <Logos logos={logos} />

        <Example
          inline={true}
          Industry={"real-estate"}
          gaTrackLabel="Real estate page"
        />

        <Section
          heading={`Welcome to the future of selling Real Estate.`}
          tagline="The way you follow up with leads will change forever."
          excerpt={`Quickpage will INSTANTLY Set you apart from the all of the other agents in your neighborhood.`}
          type="section"
          sectionTop={sectionTop}
          sectionBottom={sectionBottom}
          videoURL="https://dburs8kx36lyd.cloudfront.net/media/F5F6D79C-80D5-4459-A507-DEDEF6805B10/Tfe7N7k9SZ6XL485f.mp4-web.mp4"
          poster="https://dburs8kx36lyd.cloudfront.net/media/F5F6D79C-80D5-4459-A507-DEDEF6805B10/Tfe7N7k9SZ6XL485f.mp4-poster-00001.png"
          track={sectionTrackVideo}
          link="#"
          linkColor="#46A85C"
          linkText="Get a Quickpage example in your hands right now"
          linkOnClick={this.linkClickHandler}
          columnLeftClass="flex-center"
          lists={this.renderSectionHow()}
        />

        <Section
          heading="5 minute learning curve."
          tagline="Look like a tech genius without being one"
          excerpt="Not only is Quickpage the most effective way to grab your customers attention and actually get a reply but it's as simple as sending an email."
          image={wsst2020}
          imgClasses="-max460"
          link={registerUrl}
          linkColor="#ff0283"
          linkText="Sign up for a free trial now and see what we mean"
          component={
            <SectionSlider
              link="https://quickpage-assets.s3.amazonaws.com/public/marketing/testimonials/re/"
              number={23}
            />
          }
        />

        <Testimonials
          testimonials={[
            {
              name: "Matt Mittelstadt",
              company: "EXP Reality ",
              avatar:
                "https://quickpage-assets.s3.amazonaws.com/marketing/thumbs/thumb-mm.png",
              url:
                "https://dburs8kx36lyd.cloudfront.net/media/38159E0B-AF43-4DF3-EA6E-01D004606425/x8dmDiDfrsi2f5xX9.mp4-web.mp4",
              poster:
                "https://dburs8kx36lyd.cloudfront.net/media/38159E0B-AF43-4DF3-EA6E-01D004606425/x8dmDiDfrsi2f5xX9.mp4-poster-00001.png",
              caption: trackVideoSlider,
              playVideo: true,
            },
            {
              name: "Jean Richer",
              company: "Keller Williams Integrity Realty",
              avatar:
                "https://quickpage-assets.s3.amazonaws.com/marketing/thumbs/thumb-jr.png",
              url:
                "https://dburs8kx36lyd.cloudfront.net/media/906D45B3-4617-404E-C377-0DF6AC8C72AE/z9mPZaxM7saqjzgyx.mp4-web.mp4",
              poster:
                "https://dburs8kx36lyd.cloudfront.net/media/906D45B3-4617-404E-C377-0DF6AC8C72AE/z9mPZaxM7saqjzgyx.mp4-poster-00001.png",
            },
            {
              name: "Jason Steele",
              company: "Royal Lepage Exchange Realty",
              avatar:
                "https://quickpage-assets.s3.amazonaws.com/marketing/thumbs/thumb-js.png",
              url:
                "https://dburs8kx36lyd.cloudfront.net/media/BA58F6EE-4E7A-4B0B-BA4E-B1D8791F5D9C/NKzCewz9AdsEyCjkX.mp4-web.mp4",
              poster:
                "https://dburs8kx36lyd.cloudfront.net/media/BA58F6EE-4E7A-4B0B-BA4E-B1D8791F5D9C/NKzCewz9AdsEyCjkX.mp4-poster-00001.png",
            },
            {
              name: "Rick Sergison",
              company: "EXP Reality",
              avatar:
                "https://quickpage-assets.s3.amazonaws.com/marketing/thumbs/thumb-rs.png",
              url:
                "https://dburs8kx36lyd.cloudfront.net/media/269D7CC5-793E-4157-F7B5-8CDA01E8561A/sMTitrJpkiaPeQEfZ.mp4-web.mp4",
              poster:
                "https://dburs8kx36lyd.cloudfront.net/media/269D7CC5-793E-4157-F7B5-8CDA01E8561A/sMTitrJpkiaPeQEfZ.mp4-poster-00001.png",
            },
            {
              name: "Patrick Ashby",
              company: "EXP Reality",
              avatar:
                "https://quickpage-assets.s3.amazonaws.com/marketing/thumbs/thumb-pa.png",
              url:
                "https://dburs8kx36lyd.cloudfront.net/media/5E0ADE58-B8CE-4209-BCEB-0E9F59B0313B/3tiHNtYQanPwCeDqt.mp4-web.mp4",
              poster:
                "https://dburs8kx36lyd.cloudfront.net/media/5E0ADE58-B8CE-4209-BCEB-0E9F59B0313B/3tiHNtYQanPwCeDqt.mp4-poster-00001.png",
            },
            {
              name: "Samuel Chan",
              company: "EXP Reality",
              avatar:
                "https://quickpage-assets.s3.amazonaws.com/marketing/thumbs/thumb-sc.png",
              url:
                "https://dburs8kx36lyd.cloudfront.net/media/FB93DB9B-3292-4596-917A-055FD15D5DA1/nw2tLz7gHoJGhdzr2.mp4-web.mp4",
              poster:
                "https://dburs8kx36lyd.cloudfront.net/media/FB93DB9B-3292-4596-917A-055FD15D5DA1/nw2tLz7gHoJGhdzr2.mp4-poster-00001.png",
            },
            {
              name: "Shannon Unger",
              company: "RE/MAX Legend",
              avatar:
                "https://quickpage-assets.s3.amazonaws.com/marketing/thumbs/thumb-su.png",
              url:
                "https://dburs8kx36lyd.cloudfront.net/media/EC2A8E2D-2CDF-4456-8A45-F0368E3DFE12/uwNpTEu2d8TNEpDPE.mp4-web.mp4",
              poster:
                "https://dburs8kx36lyd.cloudfront.net/media/2A7806D5-F50E-428E-A282-4DFA80B0FCAA/cjRn3rCpxxy4HdrNx.png",
            },
          ]}
        />

        <MediaQuery maxWidth={1023}>
          {(matches) => {
            if (matches) {
              return (
                <SectionSliderContain>
                  <SectionSlider
                    link="https://quickpage-assets.s3.amazonaws.com/public/marketing/testimonials/re/"
                    number={23}
                  />
                </SectionSliderContain>
              );
            } else {
              return null;
            }
          }}
        </MediaQuery>

        <Section
          heading={`Exclusive Quickpage <span>Facebook membership.</span>`}
          excerpt="As a subscriber, you will receive access to our private <strong>Stand out Sales</strong> Facebook group full of 1000s of Quickpage users from across the continent sharing never-before-seen follow up strategies."
          lists={this.renderFBLists()}
          image={facebookGroup}
        />

        <Cta location={this.props.location} />

        {visible && (
          <ModalExample
            visible={visible}
            Industry={"real-estate"}
            closeHandler={this.linkClickHandler}
            gaTrackLabel="Real estate page"
          />
        )}
      </Layout>
    );
  }
}
