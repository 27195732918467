import React from "react";
import styled from "styled-components";

import Contain from "./contain";

const LogosSection = styled.section`
  padding: 0 0 4rem;
`;

const LogosContain = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: wrap;
  text-align: center;
`;

const Logo = styled.img`
  font-size: 0.8rem;
  font-weight: 600;
  margin: 0.5rem 1rem 1rem;
  height: 1.5rem;
  width: auto;
  opacity: 0.7;

  @media (min-width: 64rem) {
    height: 2rem;
  }
`;

export default ({ logos, ...rest }) => {
  const logosLists = logos.map((l) => <Logo key={l} src={l} />);
  return (
    <LogosSection>
      <Contain>
        <LogosContain>{logosLists}</LogosContain>
      </Contain>
    </LogosSection>
  );
};
