import React from "react";
import styled from "styled-components";

import Contain from "./contain";
import StarIcon from "./stars-icon";

const QuotesSection = styled.section`
  padding: 0 0 5rem;
`;

const QuotesContain = styled.div`
  display: flex;
  justify-content: space-around;
  flex-flow: wrap;
  text-align: center;
`;

const QuoteCard = styled.div`
  width: 100%;
  max-width: 24rem;
  background: #fff;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.05);
  padding: 1.5rem;
  flex: 1 0 auto;
  margin: 0.5rem;
`;

const Quote = styled.div`
  font-family: "Open Sans Condensed", sans-serif;
  font-size: 1.5rem;
  line-height: 1.3;
  padding: 0.5rem 0 0.8rem;
`;

const Person = styled.div`
  font-size: 0.8rem;
  font-weight: 600;
`;

const Name = styled.span``;

const Company = styled.span``;

export default ({ quotes, ...rest }) => {
  const quotesList = quotes.map((q) => (
    <QuoteCard key={q.name}>
      <StarIcon />
      <Quote>{q.quote}</Quote>
      <Person>
        <Name>{q.name}</Name> - <Company>{q.company}</Company>
      </Person>
    </QuoteCard>
  ));
  return (
    <QuotesSection>
      <Contain {...rest}>
        <QuotesContain>{quotesList}</QuotesContain>
      </Contain>
    </QuotesSection>
  );
};
