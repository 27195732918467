import React from "react";

export default ({ children, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none">
      <g fill="#A4C639" clipPath="url(#clip10)">
        <path d="M30.146 13.206H9.854a.817.817 0 00-.817.817V31.04c0 .451.366.817.817.817h3.618v5.735a2.41 2.41 0 104.818 0v-5.735h3.37v5.735a2.41 2.41 0 104.818 0v-5.735h3.668a.817.817 0 00.817-.817V14.023a.817.817 0 00-.817-.817zm-24.544-.004a2.41 2.41 0 00-2.41 2.41v9.784a2.41 2.41 0 004.819 0v-9.785a2.41 2.41 0 00-2.41-2.41zm28.796 0a2.41 2.41 0 00-2.409 2.41v9.784a2.41 2.41 0 104.819 0v-9.785a2.41 2.41 0 00-2.41-2.41zm-24.38-1.19h19.915c.52 0 .907-.478.8-.987a11.008 11.008 0 00-5.37-7.314L27.067.64a.43.43 0 00-.753-.417l-1.715 3.095a10.95 10.95 0 00-4.622-1.016 10.95 10.95 0 00-4.622 1.016L13.64.222a.43.43 0 00-.753.417l1.702 3.072a11.008 11.008 0 00-5.37 7.314.818.818 0 00.8.987zm15.9-4.578a.926.926 0 11-1.852 0 .926.926 0 011.852 0zM14.96 6.508a.926.926 0 110 1.853.926.926 0 010-1.853z"></path>
      </g>
      <defs>
        <clipPath id="clip10">
          <path fill="#fff" d="M0 0h40v40H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
