import React from "react";

export default ({ children, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="144" height="24" fill="none">
      <g clipPath="url(#clip0)">
        <path
          fill="#FFE200"
          d="M23.363 8.584l-7.378-1.127L12.678.413c-.247-.526-1.11-.526-1.357 0L8.015 7.457.637 8.584a.75.75 0 00-.423 1.265l5.36 5.494-1.267 7.767a.75.75 0 001.103.777L12 20.245l6.59 3.643a.75.75 0 001.103-.777l-1.267-7.767 5.36-5.494a.75.75 0 00-.423-1.266z"
        ></path>
      </g>
      <g clipPath="url(#clip1)">
        <path
          fill="#FFE200"
          d="M53.363 8.584l-7.378-1.127L42.678.413c-.247-.526-1.11-.526-1.357 0l-3.306 7.044-7.378 1.127a.75.75 0 00-.423 1.265l5.36 5.494-1.267 7.767a.75.75 0 001.103.777L42 20.245l6.59 3.643a.75.75 0 001.103-.777l-1.267-7.767 5.36-5.494a.75.75 0 00-.423-1.266z"
        ></path>
      </g>
      <g clipPath="url(#clip2)">
        <path
          fill="#FFE200"
          d="M83.363 8.584l-7.378-1.127L72.678.413c-.247-.526-1.11-.526-1.357 0l-3.306 7.044-7.378 1.127a.75.75 0 00-.423 1.265l5.36 5.494-1.267 7.767a.75.75 0 001.103.777L72 20.245l6.59 3.643a.75.75 0 001.103-.777l-1.267-7.767 5.36-5.494a.75.75 0 00-.423-1.266z"
        ></path>
      </g>
      <g clipPath="url(#clip3)">
        <path
          fill="#FFE200"
          d="M113.363 8.584l-7.378-1.127-3.307-7.044c-.247-.526-1.11-.526-1.357 0l-3.306 7.044-7.378 1.127a.75.75 0 00-.423 1.265l5.36 5.494-1.267 7.767a.75.75 0 001.103.777l6.59-3.642 6.59 3.643a.75.75 0 001.103-.777l-1.267-7.767 5.36-5.494a.75.75 0 00-.423-1.266z"
        ></path>
      </g>
      <g clipPath="url(#clip4)">
        <path
          fill="#FFE200"
          d="M143.363 8.584l-7.378-1.127-3.307-7.044c-.247-.526-1.11-.526-1.357 0l-3.306 7.044-7.378 1.127a.75.75 0 00-.423 1.265l5.36 5.494-1.267 7.767a.75.75 0 001.103.777l6.59-3.642 6.59 3.643a.75.75 0 001.103-.777l-1.267-7.767 5.36-5.494a.75.75 0 00-.423-1.266z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0h24v24H0z"></path>
        </clipPath>
        <clipPath id="clip1">
          <path fill="#fff" d="M30 0h24v24H30z"></path>
        </clipPath>
        <clipPath id="clip2">
          <path fill="#fff" d="M60 0h24v24H60z"></path>
        </clipPath>
        <clipPath id="clip3">
          <path fill="#fff" d="M90 0h24v24H90z"></path>
        </clipPath>
        <clipPath id="clip4">
          <path fill="#fff" d="M120 0h24v24h-24z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
