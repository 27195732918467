import React from "react";

export default ({ children, ...rest }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none">
      <path
        fill="#555"
        d="M36.209 29.378c-.108.304-.212.63-.332.95-.993 2.69-2.501 5.078-4.331 7.267a9.364 9.364 0 01-1.19 1.17c-.84.71-1.806 1.14-2.924 1.179a5.862 5.862 0 01-2.423-.442c-.563-.23-1.117-.486-1.687-.694-1.852-.673-3.694-.584-5.517.136-.657.26-1.305.547-1.974.772-.776.26-1.58.366-2.4.211-.716-.133-1.341-.466-1.922-.896-.721-.533-1.327-1.184-1.893-1.872-2.68-3.27-4.48-6.966-5.349-11.107-.374-1.78-.554-3.575-.444-5.395.129-2.182.636-4.26 1.802-6.132 1.463-2.353 3.527-3.895 6.243-4.53 1.633-.385 3.222-.228 4.78.372.819.314 1.64.624 2.464.932a3.098 3.098 0 002.271-.005c.84-.32 1.68-.64 2.524-.953.864-.315 1.744-.573 2.66-.646a9.993 9.993 0 014.015.503c1.846.618 3.349 1.711 4.483 3.303l.087.127c-2.862 1.995-4.49 4.63-4.254 8.197.239 3.57 2.16 5.985 5.31 7.553zM20.159 9.253a6.108 6.108 0 002.437-.45c3.565-1.415 5.428-5.186 5.372-8.07-.004-.232-.02-.464-.03-.733-.367.055-.7.08-1.022.161-2.602.638-4.604 2.09-5.92 4.442-.77 1.376-1.2 2.838-1.068 4.436.009.17.07.212.23.214z"
      ></path>
    </svg>
  );
};
