import React from "react";
import styled from "styled-components";
import Slider from "react-slick";

// sub-components
import Contain from "./contain";
import Row from "./row";
import Column from "./column";
import Video from "./video";

import AppleIcon from "./apple-icon";
import AndroidIcon from "./android-icon";
import FacebookIcon from "./facebook-icon";
import StarsIcon from "./stars-icon";

const TestimonialsSection = styled.section`
  padding: 5rem 0;
  background: #f5f1eb;
`;

const Heading = styled.h2``;

const Excerpt = styled.p`
  padding: 2rem 0;

  a {
    text-decoration: underline;
  }

  @media (min-width: 64rem) {
    font-size: 1.5rem;
    line-height: 1.6;
  }
`;

const SliderItem = styled.div`
  padding: 0 1rem 2rem;
  outline: none;
`;

const Meta = styled.div`
  padding: 0 0 1rem;
  display: flex;
`;

const Avatar = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  object-fit: cover;
  border-radius: 50rem;
  border: 2px solid #fff;
`;

const MetaInfo = styled.div`
  padding-left: 0.6rem;
`;

const Name = styled.div`
  margin: 0 0 0.2rem;
  font-weight: 600;
`;

const Company = styled.div`
  color: #5e5e5e;
  font-weight: 600;
  font-size: 0.8rem;
`;

const ReviewContain = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  @media (max-width: 64rem) {
    flex-direction: column;
    padding: 0 0 2rem;
  }
`;

const ButtonContain = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 0 1rem;
  flex-direction: column;
  @media (max-width: 50rem) {
    margin-bottom: 2rem;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const ReviewLink = styled.a`
  flex: 1;
  display: inline-flex;
  align-items: center;
  background: #fff;
  border-radius: 50rem;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  text-transform: uppercase;
  font-weight: 600;
  transition: all 0.3s;

  @media (max-width: 64rem) {
    width: 100%;
  }

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  > svg {
    margin-right: 0.5rem;
  }
`;

const ReviewInfo = styled.div`
  padding: 1rem 0 0;
`;

const Score = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;

  span {
    font-size: 2rem;
    font-weight: 600;
    padding-right: 0.5rem;
  }
`;

const Info = styled.div`
  fonst-size: 0.8rem;
  color: #787878;
  padding-top: 0.1rem;
  font-weight: 600;
`;

const ReviewButton = ({ url, name, icon, numbers }) => (
  <ButtonContain>
    <ReviewLink href={url} target="_blank">
      {icon}
      <span>{name}</span>
    </ReviewLink>

    <ReviewInfo>
      <Score>
        <span>{numbers} </span>
        <StarsIcon />
      </Score>
      <Info>{numbers} Reviews</Info>
    </ReviewInfo>
  </ButtonContain>
);

export default ({ testimonials = [] }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <TestimonialsSection>
      <Contain>
        <Row>
          <Column>
            <Heading>Have you ever seen reviews like this??</Heading>
            <Excerpt>
              Look at our HUNDREDS of 5 STAR, heartfelt reviews from sales
              people just like YOU.
            </Excerpt>

            <ReviewContain>
              <ReviewButton
                url="https://apps.apple.com/us/app/quickpage-app/id1087963048"
                name="Apple"
                icon={<AppleIcon />}
                numbers={62}
              />
              <ReviewButton
                url="https://play.google.com/store/apps/details?id=quickpage.app.com&hl=en"
                name="Android"
                icon={<AndroidIcon />}
                numbers={67}
              />
              <ReviewButton
                url="https://www.facebook.com/quickpageapp/reviews"
                name="Facebook"
                icon={<FacebookIcon />}
                numbers={68}
              />
            </ReviewContain>
          </Column>
          <Column half>
            <Slider {...settings}>
              {testimonials.map((t) => (
                <SliderItem key={t.url}>
                  <Meta>
                    <Avatar src={t.avatar} alt={t.name} />
                    <MetaInfo>
                      <Name>{t.name}</Name>
                      <Company>{t.company}</Company>
                    </MetaInfo>
                  </Meta>

                  <Video
                    url={t.url}
                    poster={t.poster}
                    caption={t.caption}
                    autoplay={true}
                    playVideo={t.playVideo}
                  />
                </SliderItem>
              ))}
            </Slider>
          </Column>
        </Row>
      </Contain>
    </TestimonialsSection>
  );
};
