import React from "react";

export default ({ children, ...rest }) => {
  return (
    <svg width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 40c11.046 0 20-8.954 20-20S31.046 0 20 0 0 8.954 0 20s8.954 20 20 20z"
        fill="#1977F3"
      />
      <path
        d="M25.028 20.783h-3.569v13.074h-5.407V20.783H13.48v-4.595h2.572v-2.973c0-2.126 1.01-5.456 5.455-5.456l4.005.017v4.46h-2.906c-.476 0-1.147.238-1.147 1.252v2.704H25.5l-.472 4.591z"
        fill="#fff"
      />
    </svg>
  );
};
