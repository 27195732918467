import React from "react";

export default ({ fill = "#5E5E5E" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
      <path
        fill={fill}
        fillRule="evenodd"
        d="M17.707 9.293l-5-5a1 1 0 10-1.414 1.414L14.586 9H3a1 1 0 000 2h11.586l-3.293 3.293a1 1 0 101.414 1.414l5-5a1 1 0 000-1.414z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};
