import React from "react";
import MediaQuery from "react-responsive";
import styled from "styled-components";

import Contain from "./contain";
import Row from "./row";
import Column from "./column";
import VideoFrame from "./video-frame";

import ArrowIcon from "./arrow-icon";

const Section = styled.section`
  padding: 5rem 0 5rem;

  @media (min-width: 64rem) {
    &:nth-child(even) {
      .Row {
        flex-direction: row-reverse;
      }
    }
  }

  @media (max-width: 64rem) {
    &.-mpt0 {
      padding-top: 0;
    }
  }
`;

const SectionTextContain = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  height: 100%;

  a:hover {
    opacity: 0.8;
  }
`;

const Img = styled.img`
  display: block;
  margin: 0 auto;

  &.-max460 {
    width: 100%;
    max-width: 460px;

    @media (max-width: 30rem) {
      margin: 0 0 1rem;
    }
  }
`;

const Heading = styled.h2`
  margin: 0 0 1rem;
  @media (min-width: 64rem) {
    span {
      display: block;
    }
  }
`;

const Excerpt = styled.p`
  padding: 2rem 0;

  @media (min-width: 64rem) {
    font-size: 1.5rem;
    line-height: 1.6;
  }
`;

const Tagline = styled.div`
  font-size: 0.8rem;
  letter-spacing: 0.2rem;
  text-transform: uppercase;
  font-weight: 800;
  color: #5e5e5e;
  margin: 0 0 1rem;
`;
const Link = styled.a`
  font-weight: 700;
  display: inline-block;

  &:hover {
    text-decoration: underline;
  }

  svg {
    margin-left: 0.5rem;
    position: relative;
    top: 0.2rem;
  }
`;

const Lists = styled.div`
  li {
    font-weight: 600;
  }
`;

const Iphone = styled.div`
  width: 312px;
  height: 640px;
  background: #fff;
  border-radius: 35px;
  box-shadow: inset 0 0 16px rgba(0, 0, 0, 0.1), 0 16px 32px rgba(0, 0, 0, 0.1);
  position: relative;
  margin: 0 auto;

  @media (max-width: 64rem) {
    margin: 0 auto 2rem;
  }

  > div {
    position: absolute;
    top: 16px;
    left: 16px;
    width: calc(100% - 32px);
    height: calc(100% - 32px);
    border-radius: 25px;
    box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.2);
    background: #fff;
    overflow: hidden;
  }
  &:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 50%;
    transform: translateX(-50%);
    width: 130px;
    height: 16px;
    background: #fff;
    border-radius: 0 0 10px 10px;
  }
`;

const Video = styled.video`
  display: block;
  margin: 0 auto;
`;

export default ({
  heading,
  tagline,
  excerpt,
  image,
  link,
  linkColor = "#5E5E5E",
  linkText,
  linkOnClick,
  lists,
  type = "image",
  columnLeftClass,
  columnRightClass,
  sectionTop,
  sectionBottom,
  videoURL,
  poster,
  track,
  imgClasses,
  component,
}) => {
  return (
    <Section className={imgClasses ? "-mpt0" : ""}>
      <Contain>
        <Row>
          {component ? (
            <MediaQuery minWidth={1024}>
              {(matches) => {
                if (matches) {
                  return <Column classes={columnLeftClass}>{component}</Column>;
                } else {
                  return null;
                }
              }}
            </MediaQuery>
          ) : (
            <Column classes={columnLeftClass}>
              {type === "section" ? (
                <Iphone>
                  <div>
                    <Img src={sectionTop} alt={heading} />
                    <VideoFrame
                      video={videoURL}
                      poster={poster}
                      videoControls={true}
                      track={track}
                    />
                    <Img src={sectionBottom} alt={heading} />
                  </div>
                </Iphone>
              ) : type === "image" ? (
                <Img src={image} alt={heading} className={imgClasses} />
              ) : (
                <Iphone>
                  <div>
                    <Video
                      src={image}
                      playsInline={true}
                      autoPlay={true}
                      loop={true}
                      muted={true}
                    />
                  </div>
                </Iphone>
              )}
            </Column>
          )}

          <Column classes={columnRightClass}>
            <SectionTextContain>
              <Heading dangerouslySetInnerHTML={{ __html: heading }} />
              {tagline && <Tagline>{tagline}</Tagline>}
              <Excerpt dangerouslySetInnerHTML={{ __html: excerpt }} />
              {lists && <Lists>{lists}</Lists>}
              {link && (
                <Link
                  style={{ color: linkColor, textDecoration: "underline" }}
                  href={link}
                  onClick={linkOnClick}
                >
                  {linkText} <ArrowIcon fill={linkColor} />
                </Link>
              )}
            </SectionTextContain>
          </Column>
        </Row>
      </Contain>
    </Section>
  );
};
