import React from "react";
import styled from "styled-components";
import Slider from "react-slick";

// sub-components
import Contain from "./contain";
import Row from "./row";
import Column from "./column";
import Video from "./video";

import AppleIcon from "./apple-icon";
import AndroidIcon from "./android-icon";
import FacebookIcon from "./facebook-icon";
import StarsIcon from "./stars-icon";

const SectionSliderDiv = styled.section``;

const SliderItem = styled.div`
  height: 25rem;
  display: flex;
  justify-content: center;
  align-items: center;

  > .SliderItem__bg {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
`;

export default ({
  link = "https://quickpage-assets.s3.amazonaws.com/public/marketing/testimonials/",
  number = 33,
}) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const rows = [];
  for (var i = 0; i < number; i++) {
    rows.push(i);
  }

  return (
    <SectionSliderDiv>
      <Slider {...settings}>
        {rows.map((e, i) => (
          <SliderItem key={`t+${i}`}>
            <div
              className="SliderItem__bg"
              style={{
                backgroundImage: `url(${link}${i + 1}.jpg)`,
              }}
            />
          </SliderItem>
        ))}
      </Slider>
    </SectionSliderDiv>
  );
};
